<template>
  <div class="animated fadeIn">
    <b-card
      header-tag="header"
      footer-tag="footer">
      <div slot="header">
        <strong> Filtros </strong>
      </div>
      <div class="p-1 mt-2">
        <b-row class="mb-1">
          <b-col sm="12" md="4" class="d-md-block">
            <b-button-toolbar class="float-left">
              <b-form-radio-group class="mr-3" buttons button-variant="outline-primary" v-model="filtro.fields.periodo" @change="changePeriodo" name="radiosBtn">
                <b-form-radio class="mx-0" value="Hoje">Hoje</b-form-radio>
                <b-form-radio class="mx-0" value="Ontem">Ontem</b-form-radio>
                <b-form-radio class="mx-0" value="Semana">Semana</b-form-radio>
                <b-form-radio class="mx-0" value="Mes">Mês</b-form-radio>
                <b-form-radio class="mx-0" value="Periodo">Período</b-form-radio>
              </b-form-radio-group>
            </b-button-toolbar>
          </b-col>
          <b-col sm="12" md="8">
            <div class="custom-control custom-checkbox pt-1">
              <input type="checkbox" class="custom-control-input" id="chkDesconsiderarPecas" v-model="filtro.fields.desconsiderarPecas">
              <label class="custom-control-label" for="chkDesconsiderarPecas">Desconsiderar devoluções em "Peças"</label>
            </div>
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col sm="4" class="pr-md-0 mb-1 mb-md-0">
            <b-form-select :plain="true" v-model="filtro.fields.gestao" :options="filtro.listas.gestao" @change="filterLojas"></b-form-select>
          </b-col>
          <b-col sm="4" class="pr-md-0 mb-1 mb-md-0">
            <b-form-select :plain="true" v-model="filtro.fields.centres" :options="filtro.listas.centres"></b-form-select>
          </b-col>
          <b-col sm="3" class="pr-md-0">
            <b-form-select :plain="true" v-model="filtro.fields.formaVenda" :options="filtro.listas.formaVenda"></b-form-select>
          </b-col>
        </b-row>

        <b-row class="mb-1">
          <b-col cols="6" sm="6" md="4" class="pr-md-0 pr-1 mb-1 mb-md-0">
            <b-form-input :plain="true" v-model="filtro.fields.dataIni" type="date" @change="dataChanged"></b-form-input>
          </b-col>
          <b-col cols="6" sm="6" md="4" class="pr-md-0 pl-1 pl-md-3 mb-1 mb-md-0">
            <b-form-input :plain="true" v-model="filtro.fields.dataFim" type="date" @change="dataChanged"></b-form-input>
          </b-col>
          <b-col sm="3" class="pr-md-0">
            <b-form-select :plain="true" v-model="filtro.fields.compararPeriodo" :options="filtro.listas.compararPeriodo"></b-form-select>
          </b-col>
          <b-col sm="1">
            <b-button variant="primary" class="my-2 my-sm-0 text-white" :class="{'btn-block': $mq === 'sm'}" @click="search()">
              <i class="fa fa-search"></i>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-row>
      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up vendas p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Vendas</div>
            <div class="text-right mb-1 h5">{{items.totais.vendas | currency}}</div>
            <div class="d-flex">
              <span class="text-muted">Passado</span>
              <span class="ml-auto">{{ items.totaisComparacao.vendas | currency }}</span>
            </div>
            <div class="d-flex">
              <span class="text-muted">Variação</span>
              <span class="ml-auto" :class="getPercentageVariacao('vendas').class">
                {{ getPercentageVariacao('vendas').value }} | {{ (items.totais.vendas - items.totaisComparacao.vendas) | currency }}
              </span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up pecas p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Peças</div>
            <div class="text-right mb-1 h5">{{ items.totais.pecas | number }}</div>
            <div class="d-flex">
              <span class="text-muted">Passado</span>
              <span class="ml-auto">{{ items.totaisComparacao.pecas | number }}</span>
            </div>
            <div class="d-flex">
              <span class="text-muted">Variação</span>
              <span class="ml-auto" :class="getPercentageVariacao('pecas').class">
                {{ getPercentageVariacao('pecas').value }} | {{ (items.totais.pecas - items.totaisComparacao.pecas) | number }}
              </span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up atendimentos p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Atendimentos</div>
            <div class="text-right mb-1 h5">{{items.totais.atendimentos | number}}</div>
            <div class="d-flex">
              <span class="text-muted">Passado</span>
              <span class="ml-auto">{{items.totaisComparacao.atendimentos | number}}</span>
            </div>
            <div class="d-flex">
              <span class="text-muted">Variação</span>
              <span class="ml-auto" :class="getPercentageVariacao('atendimentos').class">
                {{ getPercentageVariacao('atendimentos').value }} | {{ (items.totais.atendimentos - items.totaisComparacao.atendimentos) | number }}
              </span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up vendas-pecas p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Vendas/Peças</div>
            <div class="text-right mb-1 h5">{{items.totais.vp | currency}}</div>
            <div class="d-flex">
              <span class="text-muted">Passado</span>
              <span class="ml-auto">{{items.totaisComparacao.vp | currency}}</span>
            </div>
            <div class="d-flex">
              <span class="text-muted">Variação</span>
              <span class="ml-auto" :class="getPercentageVariacao('vp').class">
                {{ getPercentageVariacao('vp').value }} | {{ (items.totais.vp - items.totaisComparacao.vp) | currency }}
              </span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up vendas-atendimentos p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Vendas/Atendimentos</div>
            <div class="text-right mb-1 h5">{{items.totais.va | currency}}</div>
            <div class="d-flex">
              <span class="text-muted">Passado</span>
              <span class="ml-auto">{{items.totaisComparacao.va | currency}}</span>
            </div>
            <div class="d-flex">
              <span class="text-muted">Variação</span>
              <span class="ml-auto" :class="getPercentageVariacao('va').class">
                {{ getPercentageVariacao('va').value }} | {{ (items.totais.va - items.totaisComparacao.va) | currency }}
              </span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up pecas-atendimentos p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Peças/Atendimentos</div>
            <div class="text-right mb-1 h5">{{Number(items.totais.pa || 0).toFixed(2).replace('.', ',')}}</div>
            <div class="d-flex">
              <span class="text-muted">Passado</span>
              <span class="ml-auto">{{Number(items.totaisComparacao.pa || 0).toFixed(2).replace('.', ',')}}</span>
            </div>
            <div class="d-flex">
              <span class="text-muted">Variação</span>
              <span class="ml-auto" :class="getPercentageVariacao('pa').class">
                {{ getPercentageVariacao('pa').value }} | {{ Number((items.totais.pa - items.totaisComparacao.pa) || 0).toFixed(2) }}
              </span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up cadastros p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Cadastros de Clientes</div>
            <div class="text-right mb-1 h5">{{ items.totais.clientes | number }}</div>
            <div class="d-flex">
              <span class="text-muted">Passado</span>
              <span class="ml-auto">{{ items.totaisComparacao.clientes | number }}</span>
            </div>
            <div class="d-flex">
              <span class="text-muted">Variação</span>
              <span class="ml-auto" :class="getPercentageVariacao('clientes').class">
                {{ getPercentageVariacao('clientes').value }} | {{ Number((items.totais.clientes - items.totaisComparacao.clientes) || 0) | number }}
              </span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up taxa-conversao p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Taxa de Conversão</div>
            <div class="text-right mb-1 h5">{{ items.totais.taxa | number }}%</div>
            <div class="d-flex">
              <span class="text-muted">Passado</span>
              <span class="ml-auto">{{ items.totaisComparacao.taxa | number }}%</span>
            </div>
            <div class="d-flex">
              <span class="text-muted">Variação</span>
              <span class="ml-auto" :class="getPercentageVariacao('taxa').class">
                {{ getPercentageVariacao('taxa').value }} | {{ Number((items.totais.taxa - items.totaisComparacao.taxa) || 0).toFixed(2) }}
              </span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="4">
        <b-card :no-body="true">
          <b-card-body class="clearfix">
            <i class="icon-up descontos p-3 font-2xl mr-3 float-left float-up"></i>
            <div class="text-right mb-1 h5">Descontos</div>
            <div class="text-right mb-1 h5">{{ items.totais.descontos | currency }}</div>
            <div class="d-flex">
              <span class="text-muted">Passado</span>
              <span class="ml-auto">{{ items.totaisComparacao.descontos | currency }}</span>
            </div>
            <div class="d-flex">
              <span class="text-muted">Variação</span>
              <span class="ml-auto" :class="getPercentageVariacao('descontos').class">
                {{ getPercentageVariacao('descontos').value }} | {{ (items.totais.descontos - items.totaisComparacao.descontos) | currency }}
              </span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" sm="12" lg="12">
        <c-table :table-data="items.centres" :fields="fields.centres" :per-page="25" striped small :caption="tableTitle" :read-more="readMore.vendas"></c-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" sm="6" lg="6">
        <c-table :table-data="items.vendedores" :fields="fields.vendedores" :no-pagination="true" :per-page="5" striped small caption="Top 5 Vendedores" :read-more="readMore.vendedores"></c-table>
      </b-col>
      <b-col cols="12" sm="6" lg="6">
        <c-table :table-data="items.produtos" :fields="fields.produtos" :no-pagination="true" :per-page="5" striped small caption="Top 5 Produtos" :read-more="readMore.produtos"></c-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" sm="12" md="6">
        <b-card>
          <div slot="header">
            <div class="d-flex">
              <div class="d-flex-inline mr-auto text-primary">
                <strong>Fluxo de Vendas</strong>
              </div>
              <div class="d-flex-inline ml-auto">
                <i class="fa fa-download text-primary cursor-pointer" @click="export2image('chart-line-01', `Fluxo de Vendas`)" title="Exportar"></i>
              </div>
            </div>
          </div>
          <line-chart :chartdata="charts.fluxoAtendimento" :height="245" :moneyFormat="true" chartId="chart-line-01" v-if="loaded" v-show="chartFluxoAtendimento.length > 0" />
          <div class="alert alert-secondary text-center" v-show="chartFluxoAtendimento.length === 0">
            Nenhum resultado encontrado.
          </div>
          <b-row v-if="readMore.fluxoAtendimento">
            <b-col cols="12" sm="12" class="text-right">
              <b-link :to="readMore.fluxoAtendimento.link">{{ readMore.fluxoAtendimento.text }}</b-link>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="12" sm="12" md="6">
        <b-card>
          <div slot="header">
            <div class="d-flex">
              <div class="d-flex-inline mr-auto text-primary">
                <strong>Top 5 Vendas Perdidas</strong>
              </div>
              <div class="d-flex-inline ml-auto">
                <i class="fa fa-download text-primary cursor-pointer" @click="export2image('chart-01', `Top 5 Vendas Perdidas`)" title="Exportar"></i>
              </div>
            </div>
          </div>
          <div id="chart-01">
            <div class="alert alert-secondary text-center" v-if="motivosVendasPerdidas.length === 0">
              Nenhum resultado encontrado
            </div>
            <div v-if="motivosVendasPerdidas.length > 0">
              <ul class="horizontal-bars pl-0" v-for="(item, index) in motivosVendasPerdidas" :key="index">
                <div class="progress-group">
                  <div class="progress-group-header">
                    <span class="title">{{item.nome_motivo}}</span>
                    <span class="ml-auto font-weight-bold">{{item.qtde_motivo}} <span class="text-muted small">({{ item.perc_motivo | currency({symbol: ''}) }}%)</span></span>
                  </div>
                  <div class="progress-group-bars">
                    <b-progress height={} class="progress-sm" :value="item.perc_motivo" variant="primary"></b-progress>
                  </div>
                </div>
              </ul>
            </div>
          </div>
          <b-row v-if="readMore.vendasPerdidas">
            <b-col cols="12" sm="12" class="text-right">
              <b-link :to="readMore.vendasPerdidas.link">{{ readMore.vendasPerdidas.text }}</b-link>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { shuffleArray } from '@/shared/utils'
import cTable from '@/core/components/Table.vue'
import HorizontalBar from '@/core/components/charts/HorizontalBar';
import LineChart from '@/core/components/charts/LineChart';
import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities'
import moment from 'moment';
import API from '@/core/api';
import session from '@/shared/session'

export default {
  name: 'Dashboard',
  components: {
    cTable,
    HorizontalBar,
    LineChart
  },
  data: () => {
    return {
      filtro: {
        listas: {
          gestao: session.get('gestoes'),
          centres: session.get('lojas'),
          centresDefault: session.get('lojas'),
          formaVenda: session.get('formas'),
          compararPeriodo: [{value: 1, text: 'Comparar com período anterior'}, {value: 2, text: 'Comparar com mês anterior'}, {value: 3, text: 'Comparar com ano anterior'}]
        },
        fields: {
          periodo: 'Hoje',
          gestao: 0,
          centres: 0,
          formaVenda: 0,
          dataIni: moment().format('YYYY-MM-DD'),
          dataFim: moment().format('YYYY-MM-DD'),
          compararPeriodo: 1,
          desconsiderarPecas: false
        }
      },
      metas: [],
      items: {
        totais: {},
        totaisComparacao: {},
        centres: [],
        vendedores: [],
        produtos: []
      },
      fields: {
        centres: [
          {key: 'n', label: 'Nº', sortable: true},
          {key: 'descricao', label: 'Descrição', sortable: true},
          {key: 'meta', label: 'Meta', class: 'text-center'},
          {key: 'vendas', label: 'Vendas R$', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'pecas', label: 'Peças', sortable: true, number: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'atendimentos', label: 'Atend.', sortable: true, number: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'vp', label: 'V/P R$', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'va', label: 'V/A R$', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'pa', label: 'P/A', sortable: true, number: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'clientes', label: 'Clientes', sortable: true, number: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'taxa_conversao', label: 'Tx. Conv. %', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'descontos', label: 'Descontos R$', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'}
        ],
        vendedores: [
          {key: 'n', label: 'Nº', sortable: true},
          {key: 'descricao', label: 'Vendedor', sortable: true},
          {key: 'centres', label: 'Loja', sortable: true},
          {key: 'vendas', label: 'Vendas', sortable: true, currency: true, tdClass: 'text-right'}
        ],
        produtos: [
          {key: 'n', label: 'Nº', sortable: true},
          {key: 'referencia', label: 'Referência', sortable: true},
          {key: 'descricao', label: 'Descrição', sortable: true},
          {key: 'vendas', label: 'Vendas', sortable: true, currency: true, tdClass: 'text-right'}
        ]
      },
      charts: {
        fluxoAtendimento: {}
      },
      readMore: {
        vendas: {
          link: '/vendas',
          text: 'ir para Vendas'
        },
        vendedores: {
          link: '/vendas',
          text: 'ir para Vendas'
        },
        produtos: {
          link: '/produtos-mais-vendidos',
          text: 'ir para Produtos Mais Vendidos'
        },
        vendasPerdidas: {
          link: '/vendas-perdidas',
          text: 'ir para Vendas Perdidas'
        },
        fluxoAtendimento: {
          link: '/fluxo-atendimentos',
          text: 'ir para Fluxo de Atendimentos'
        }
      },
      tableTitle: 'Lojas',
      motivosVendasPerdidas: [],
      chartFluxoAtendimento: [],
      loaded: false
    }
  },
  methods: {
    async search() {
      try {
        if (this.validaPeriodo() === true) {
          this.loaded = false
          this.setPeriodoComparacao();
          const metasAPI = new API('api/metas-por-loja');
          const metas = await metasAPI.get({ ...this.parseFilters(this.filtro.fields) });
          this.metas = metas.data;

          const dashboardAPI = new API('api/dashboard');
          const {data} = await dashboardAPI.get({ ...this.parseFilters(this.filtro.fields) });
          this.definirMeta(data.centres);
          this.fluxoAtendimento(data.fluxo);
          this.items = data

          const perdidasAPI = new API('api/vendas-perdidas');
          const data2 = await perdidasAPI.get({
            ...this.parseFilters(this.filtro.fields)
          });
          this.motivosVendasPerdidas = data2.data.data;
          if (this.motivosVendasPerdidas.length > 0) {
            this.motivosVendasPerdidas = this.motivosVendasPerdidas.map(d =>
              d.records.map(r => {
                return { ...r, motivos: JSON.parse(r.motivos) };
              })
            )[0];

            let motivos = [];
            let total   = 0;
            const motivosVendasPerdidas = this.motivosVendasPerdidas.map(i => {
              i.motivos.map(mot => {
                let motivo = motivos.find(m => m.motivo === mot.motivo);
                let motivoIndex = _.findIndex(motivos, {motivo: mot.motivo});
                if (motivoIndex >= 0) {
                  motivos[motivoIndex].qtde_motivo = motivos[motivoIndex].qtde_motivo + mot.qtde_motivo;
                } else {
                  motivos.push(mot);
                }
              });
            });

            if (motivos.length > 0) {
              total   = motivos.map(m => { return m.qtde_motivo }).reduce((acc, curr) => parseFloat(acc) + parseFloat(curr));
              motivos = motivos.map(m => { return {...m, perc_motivo: (100 * m.qtde_motivo / (total === 0 ? 1 : total))}});
            }

            this.motivosVendasPerdidas = _.orderBy(motivos, ['qtde_motivo'], ['desc']).slice(0, 5);

          }

          if (this.filtro.fields.centres !== 0) {
            this.tableTitle = 'Vendedores'
          } else {
            this.tableTitle = 'Lojas'
          }

          this.loadCharts();
        }
      } catch (e) {
        console.error(e)
      }
    },
    loadCharts() {
      this.charts.fluxoAtendimento = {
        labels: this.chartFluxoAtendimento.map(f => f.exibicao),
        datasets: [
          {
            label: 'Período',
            backgroundColor: hexToRgba('#24B069', 10),
            borderColor: '#24B069',
            pointHoverBackgroundColor: '#fff',
            borderWidth: 2,
            data: this.chartFluxoAtendimento.map(f => f.vendas.periodo)
          },
          {
            label: 'Passado',
            backgroundColor: 'transparent',
            borderColor: '#C3D099',
            pointHoverBackgroundColor: '#fff',
            borderWidth: 2,
            data: this.chartFluxoAtendimento.map(f => f.vendas.passado)
          }
        ]
      }
      this.loaded = true;
    },
    definirMeta(lojas) {
      lojas.map(e => {
        const porLojaOuVendedor = this.filtro.fields.centres !== 0 ? 'vendedor' : 'loja';
        const loja = this.metas.find(m => m[porLojaOuVendedor] === e.cod)
        if (!loja) {
          e.meta = null;
        } else {
          e.meta = Number(e.vendas) < Number(loja.meta_a) ? 0
            : Number(e.vendas) >= Number(loja.meta_a) && Number(e.vendas) < Number(loja.meta_b) ? 1
              : Number(e.vendas) >= Number(loja.meta_b) && Number(e.vendas) < Number(loja.meta_c) ? 2
                : Number(e.vendas) >= Number(loja.meta_c) && Number(e.vendas) < Number(loja.meta_d) ? 3
                  : 4
        }
        return e;
      });
    },
    fluxoAtendimento(fluxo) {
      this.chartFluxoAtendimento = []
      const points = [9, 12, 15, 18, 21, 24]
      for (const i in points) {
        let _fluxoPeriodo = 0
        let _fluxoPassado = 0
        let _vendasPeriodo = []
        let _vendasPassado = []

        if (Number(i) === 0) {
          _vendasPeriodo = fluxo.periodo.filter(f => f.exibicao <= points[i])
          _vendasPassado = fluxo.passado.filter(f => f.exibicao <= points[i])
        } else {
          _vendasPeriodo = fluxo.periodo.filter(f => f.exibicao >= points[Number(i)-1] && f.exibicao < points[Number(i)])
          _vendasPassado = fluxo.passado.filter(f => f.exibicao >= points[Number(i)-1] && f.exibicao < points[Number(i)])
        }

        if (_vendasPeriodo.length > 0) {
          _fluxoPeriodo = _vendasPeriodo.map(f => Number(f.vendas)).reduce((acum, obj) => acum + obj)
        }

        if (_vendasPassado.length > 0) {
          _fluxoPassado = _vendasPassado.map(f => Number(f.vendas)).reduce((acum, obj) => acum + obj)
        }

        this.chartFluxoAtendimento.push({
          exibicao: `${points[Number(i)]}h`,
          vendas: {
            periodo: _fluxoPeriodo,
            passado: _fluxoPassado
          }
        })
      }
    },
    validaPeriodo() {
      const { dataIni, dataFim } = this.filtro.fields;
      let invalid = false;

      if (
        dataIni === '' ||
        dataFim === '' ||
        moment(dataIni).isAfter(moment(dataFim)) ||
        !moment(dataIni).isValid() ||
        !moment(dataFim).isValid()
      ) {
        invalid = true;
      }

      if (invalid) {
        return swal({
          text: 'Data inválida.'
        });
      }
      return true
    },
    dataChanged() {
      this.filtro.fields.periodo = 'Periodo';
    },
    changePeriodo(periodo) {
      switch(periodo) {
        case 'Hoje': {
          this.filtro.fields.dataIni = moment().format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().format('YYYY-MM-DD');
          break;
        }
        case 'Ontem': {
          this.filtro.fields.dataIni = moment().subtract(1, 'day').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().subtract(1, 'day').format('YYYY-MM-DD');
          break;
        }
        case 'Semana': {
          this.filtro.fields.dataIni = moment().startOf('week').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().endOf('week').format('YYYY-MM-DD');
          break;
        }
        case 'Mes': {
          this.filtro.fields.dataIni = moment().startOf('month').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().endOf('month').format('YYYY-MM-DD');
          break;
        }
        default: return;
      }
      this.search();
    },
    setPeriodoComparacao() {
      const dataIni = moment(this.filtro.fields.dataIni);
      const dataFim = moment(this.filtro.fields.dataFim);

      let dif = 1;
      let unit = 'days';

      switch (this.filtro.fields.compararPeriodo) {
        case 1:
          dif = dataFim.diff(dataIni, 'days') + 1;
          unit = 'days'
          break
        case 2:
          dif = 1
          unit = 'month'
          break
        case 3:
          dif = 1
          unit = 'year'
          break
      }

      this.filtro.fields['periodoIni'] = dataIni.subtract(dif, unit).format('YYYY-MM-DD');
      this.filtro.fields['periodoFim'] = dataFim.subtract(dif, unit).format('YYYY-MM-DD');
    },
    getPercentageVariacao(type) {
      const diff = (this.items.totais[type] - this.items.totaisComparacao[type])
      const divisor = this.items.totaisComparacao[type] > 0 ? this.items.totaisComparacao[type] : 1
      const result = ((diff * 100) / divisor) || 0
      const signal = ( Math.abs(result.toFixed(2)) === 0 || diff === 0 || isNaN(diff) ) ? '' : (diff > 0 ? '+' : '-')
      return {
        signal,
        value: (signal + Math.abs(result.toFixed(2)) + '%').replace('.',','),
        class: signal === '' ? '' : (signal === '+' ? 'text-success' : 'text-danger')
      }
    },
    parseFilters(filters) {
      let filter = { ...filters };
      const {
        gestao,
        centres,
        formaVenda
      } = filters;

      const gestoes = this.filtro.listas.gestao.map(c => c.value).join(',')
      const lojas = this.filtro.listas.centres.map(c => c.value).join(',')

      filter.gestao = gestao === 0 ? gestoes : gestao;
      filter.centres = centres === 0 ? lojas : centres;
      filter.formaVenda = formaVenda !== 0 ? [formaVenda] : 0;
      if (typeof filter.centres === 'number') {
        filter.listaPorVendedor = true
      }
      return filter;
    },
    export2image: function (id, name) {
      // Set White Background
      let chart = document.getElementById(id)
      var ctx = chart.getContext("2d");
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = "#FFF";
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();

      // Download image
      let canvas = document.getElementById(id).toDataURL('image/png')
      let link = document.createElement('a')
      link.download = name || 'Grafico'
      link.href = canvas
      link.click()
    },
    filterLojas() {
      const { gestao, centres } = this.filtro.fields;
      const { centresDefault } = this.filtro.listas;
      this.filtro.listas.centres = gestao === 0 ? centresDefault
        : centresDefault.filter(l => l.gestao === gestao || l.value === 0)

      const gestaoCentres = centresDefault.find(c => c.value === centres)
      if (gestaoCentres.gestao !== gestao) {
        this.filtro.fields.centres = 0
      }
    }
  },
  created() {
    this.search();
  }
}
</script>
