var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card",
        { attrs: { "header-tag": "header", "footer-tag": "footer" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("strong", [_vm._v(" Filtros ")])
          ]),
          _c(
            "div",
            { staticClass: "p-1 mt-2" },
            [
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-md-block", attrs: { sm: "12", md: "4" } },
                    [
                      _c(
                        "b-button-toolbar",
                        { staticClass: "float-left" },
                        [
                          _c(
                            "b-form-radio-group",
                            {
                              staticClass: "mr-3",
                              attrs: {
                                buttons: "",
                                "button-variant": "outline-primary",
                                name: "radiosBtn"
                              },
                              on: { change: _vm.changePeriodo },
                              model: {
                                value: _vm.filtro.fields.periodo,
                                callback: function($$v) {
                                  _vm.$set(_vm.filtro.fields, "periodo", $$v)
                                },
                                expression: "filtro.fields.periodo"
                              }
                            },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Hoje" }
                                },
                                [_vm._v("Hoje")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Ontem" }
                                },
                                [_vm._v("Ontem")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Semana" }
                                },
                                [_vm._v("Semana")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Mes" }
                                },
                                [_vm._v("Mês")]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  staticClass: "mx-0",
                                  attrs: { value: "Periodo" }
                                },
                                [_vm._v("Período")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-col", { attrs: { sm: "12", md: "8" } }, [
                    _c(
                      "div",
                      { staticClass: "custom-control custom-checkbox pt-1" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.filtro.fields.desconsiderarPecas,
                              expression: "filtro.fields.desconsiderarPecas"
                            }
                          ],
                          staticClass: "custom-control-input",
                          attrs: {
                            type: "checkbox",
                            id: "chkDesconsiderarPecas"
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.filtro.fields.desconsiderarPecas
                            )
                              ? _vm._i(
                                  _vm.filtro.fields.desconsiderarPecas,
                                  null
                                ) > -1
                              : _vm.filtro.fields.desconsiderarPecas
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.filtro.fields.desconsiderarPecas,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.filtro.fields,
                                      "desconsiderarPecas",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.filtro.fields,
                                      "desconsiderarPecas",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.filtro.fields,
                                  "desconsiderarPecas",
                                  $$c
                                )
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "custom-control-label",
                            attrs: { for: "chkDesconsiderarPecas" }
                          },
                          [_vm._v('Desconsiderar devoluções em "Peças"')]
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "pr-md-0 mb-1 mb-md-0", attrs: { sm: "4" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          plain: true,
                          options: _vm.filtro.listas.gestao
                        },
                        on: { change: _vm.filterLojas },
                        model: {
                          value: _vm.filtro.fields.gestao,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro.fields, "gestao", $$v)
                          },
                          expression: "filtro.fields.gestao"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pr-md-0 mb-1 mb-md-0", attrs: { sm: "4" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          plain: true,
                          options: _vm.filtro.listas.centres
                        },
                        model: {
                          value: _vm.filtro.fields.centres,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro.fields, "centres", $$v)
                          },
                          expression: "filtro.fields.centres"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pr-md-0", attrs: { sm: "3" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          plain: true,
                          options: _vm.filtro.listas.formaVenda
                        },
                        model: {
                          value: _vm.filtro.fields.formaVenda,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro.fields, "formaVenda", $$v)
                          },
                          expression: "filtro.fields.formaVenda"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-1" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "pr-md-0 pr-1 mb-1 mb-md-0",
                      attrs: { cols: "6", sm: "6", md: "4" }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { plain: true, type: "date" },
                        on: { change: _vm.dataChanged },
                        model: {
                          value: _vm.filtro.fields.dataIni,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro.fields, "dataIni", $$v)
                          },
                          expression: "filtro.fields.dataIni"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "pr-md-0 pl-1 pl-md-3 mb-1 mb-md-0",
                      attrs: { cols: "6", sm: "6", md: "4" }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { plain: true, type: "date" },
                        on: { change: _vm.dataChanged },
                        model: {
                          value: _vm.filtro.fields.dataFim,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro.fields, "dataFim", $$v)
                          },
                          expression: "filtro.fields.dataFim"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pr-md-0", attrs: { sm: "3" } },
                    [
                      _c("b-form-select", {
                        attrs: {
                          plain: true,
                          options: _vm.filtro.listas.compararPeriodo
                        },
                        model: {
                          value: _vm.filtro.fields.compararPeriodo,
                          callback: function($$v) {
                            _vm.$set(_vm.filtro.fields, "compararPeriodo", $$v)
                          },
                          expression: "filtro.fields.compararPeriodo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "1" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "my-2 my-sm-0 text-white",
                          class: { "btn-block": _vm.$mq === "sm" },
                          attrs: { variant: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.search()
                            }
                          }
                        },
                        [_c("i", { staticClass: "fa fa-search" })]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up vendas p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Vendas")
                    ]),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.items.totais.vendas))
                      )
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Passado")
                      ]),
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.items.totaisComparacao.vendas
                            )
                          )
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Variação")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "ml-auto",
                          class: _vm.getPercentageVariacao("vendas").class
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.getPercentageVariacao("vendas").value
                              ) +
                              " | " +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.items.totais.vendas -
                                    _vm.items.totaisComparacao.vendas
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up pecas p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Peças")
                    ]),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v(_vm._s(_vm._f("number")(_vm.items.totais.pecas)))
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Passado")
                      ]),
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("number")(_vm.items.totaisComparacao.pecas)
                          )
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Variação")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "ml-auto",
                          class: _vm.getPercentageVariacao("pecas").class
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getPercentageVariacao("pecas").value) +
                              " | " +
                              _vm._s(
                                _vm._f("number")(
                                  _vm.items.totais.pecas -
                                    _vm.items.totaisComparacao.pecas
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up atendimentos p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Atendimentos")
                    ]),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v(
                        _vm._s(_vm._f("number")(_vm.items.totais.atendimentos))
                      )
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Passado")
                      ]),
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("number")(
                              _vm.items.totaisComparacao.atendimentos
                            )
                          )
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Variação")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "ml-auto",
                          class: _vm.getPercentageVariacao("atendimentos").class
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.getPercentageVariacao("atendimentos").value
                              ) +
                              " | " +
                              _vm._s(
                                _vm._f("number")(
                                  _vm.items.totais.atendimentos -
                                    _vm.items.totaisComparacao.atendimentos
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up vendas-pecas p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Vendas/Peças")
                    ]),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v(_vm._s(_vm._f("currency")(_vm.items.totais.vp)))
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Passado")
                      ]),
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(_vm.items.totaisComparacao.vp)
                          )
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Variação")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "ml-auto",
                          class: _vm.getPercentageVariacao("vp").class
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getPercentageVariacao("vp").value) +
                              " | " +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.items.totais.vp -
                                    _vm.items.totaisComparacao.vp
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up vendas-atendimentos p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Vendas/Atendimentos")
                    ]),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v(_vm._s(_vm._f("currency")(_vm.items.totais.va)))
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Passado")
                      ]),
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(_vm.items.totaisComparacao.va)
                          )
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Variação")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "ml-auto",
                          class: _vm.getPercentageVariacao("va").class
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getPercentageVariacao("va").value) +
                              " | " +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.items.totais.va -
                                    _vm.items.totaisComparacao.va
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up pecas-atendimentos p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Peças/Atendimentos")
                    ]),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v(
                        _vm._s(
                          Number(_vm.items.totais.pa || 0)
                            .toFixed(2)
                            .replace(".", ",")
                        )
                      )
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Passado")
                      ]),
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          _vm._s(
                            Number(_vm.items.totaisComparacao.pa || 0)
                              .toFixed(2)
                              .replace(".", ",")
                          )
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Variação")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "ml-auto",
                          class: _vm.getPercentageVariacao("pa").class
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getPercentageVariacao("pa").value) +
                              " | " +
                              _vm._s(
                                Number(
                                  _vm.items.totais.pa -
                                    _vm.items.totaisComparacao.pa || 0
                                ).toFixed(2)
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up cadastros p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Cadastros de Clientes")
                    ]),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v(
                        _vm._s(_vm._f("number")(_vm.items.totais.clientes))
                      )
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Passado")
                      ]),
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("number")(
                              _vm.items.totaisComparacao.clientes
                            )
                          )
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Variação")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "ml-auto",
                          class: _vm.getPercentageVariacao("clientes").class
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.getPercentageVariacao("clientes").value
                              ) +
                              " | " +
                              _vm._s(
                                _vm._f("number")(
                                  Number(
                                    _vm.items.totais.clientes -
                                      _vm.items.totaisComparacao.clientes || 0
                                  )
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up taxa-conversao p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Taxa de Conversão")
                    ]),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v(
                        _vm._s(_vm._f("number")(_vm.items.totais.taxa)) + "%"
                      )
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Passado")
                      ]),
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("number")(_vm.items.totaisComparacao.taxa)
                          ) + "%"
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Variação")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "ml-auto",
                          class: _vm.getPercentageVariacao("taxa").class
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getPercentageVariacao("taxa").value) +
                              " | " +
                              _vm._s(
                                Number(
                                  _vm.items.totais.taxa -
                                    _vm.items.totaisComparacao.taxa || 0
                                ).toFixed(2)
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "4" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": true } },
                [
                  _c("b-card-body", { staticClass: "clearfix" }, [
                    _c("i", {
                      staticClass:
                        "icon-up descontos p-3 font-2xl mr-3 float-left float-up"
                    }),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v("Descontos")
                    ]),
                    _c("div", { staticClass: "text-right mb-1 h5" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.items.totais.descontos))
                      )
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Passado")
                      ]),
                      _c("span", { staticClass: "ml-auto" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.items.totaisComparacao.descontos
                            )
                          )
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v("Variação")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "ml-auto",
                          class: _vm.getPercentageVariacao("descontos").class
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.getPercentageVariacao("descontos").value
                              ) +
                              " | " +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.items.totais.descontos -
                                    _vm.items.totaisComparacao.descontos
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "12", lg: "12" } },
            [
              _c("c-table", {
                attrs: {
                  "table-data": _vm.items.centres,
                  fields: _vm.fields.centres,
                  "per-page": 25,
                  striped: "",
                  small: "",
                  caption: _vm.tableTitle,
                  "read-more": _vm.readMore.vendas
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "6" } },
            [
              _c("c-table", {
                attrs: {
                  "table-data": _vm.items.vendedores,
                  fields: _vm.fields.vendedores,
                  "no-pagination": true,
                  "per-page": 5,
                  striped: "",
                  small: "",
                  caption: "Top 5 Vendedores",
                  "read-more": _vm.readMore.vendedores
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6", lg: "6" } },
            [
              _c("c-table", {
                attrs: {
                  "table-data": _vm.items.produtos,
                  fields: _vm.fields.produtos,
                  "no-pagination": true,
                  "per-page": 5,
                  striped: "",
                  small: "",
                  caption: "Top 5 Produtos",
                  "read-more": _vm.readMore.produtos
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "12", md: "6" } },
            [
              _c(
                "b-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex-inline mr-auto text-primary" },
                        [_c("strong", [_vm._v("Fluxo de Vendas")])]
                      ),
                      _c("div", { staticClass: "d-flex-inline ml-auto" }, [
                        _c("i", {
                          staticClass:
                            "fa fa-download text-primary cursor-pointer",
                          attrs: { title: "Exportar" },
                          on: {
                            click: function($event) {
                              return _vm.export2image(
                                "chart-line-01",
                                "Fluxo de Vendas"
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _vm.loaded
                    ? _c("line-chart", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.chartFluxoAtendimento.length > 0,
                            expression: "chartFluxoAtendimento.length > 0"
                          }
                        ],
                        attrs: {
                          chartdata: _vm.charts.fluxoAtendimento,
                          height: 245,
                          moneyFormat: true,
                          chartId: "chart-line-01"
                        }
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.chartFluxoAtendimento.length === 0,
                          expression: "chartFluxoAtendimento.length === 0"
                        }
                      ],
                      staticClass: "alert alert-secondary text-center"
                    },
                    [
                      _vm._v(
                        "\n          Nenhum resultado encontrado.\n        "
                      )
                    ]
                  ),
                  _vm.readMore.fluxoAtendimento
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "text-right",
                              attrs: { cols: "12", sm: "12" }
                            },
                            [
                              _c(
                                "b-link",
                                {
                                  attrs: {
                                    to: _vm.readMore.fluxoAtendimento.link
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.readMore.fluxoAtendimento.text)
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "12", md: "6" } },
            [
              _c(
                "b-card",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex-inline mr-auto text-primary" },
                        [_c("strong", [_vm._v("Top 5 Vendas Perdidas")])]
                      ),
                      _c("div", { staticClass: "d-flex-inline ml-auto" }, [
                        _c("i", {
                          staticClass:
                            "fa fa-download text-primary cursor-pointer",
                          attrs: { title: "Exportar" },
                          on: {
                            click: function($event) {
                              return _vm.export2image(
                                "chart-01",
                                "Top 5 Vendas Perdidas"
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _c("div", { attrs: { id: "chart-01" } }, [
                    _vm.motivosVendasPerdidas.length === 0
                      ? _c(
                          "div",
                          { staticClass: "alert alert-secondary text-center" },
                          [
                            _vm._v(
                              "\n            Nenhum resultado encontrado\n          "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.motivosVendasPerdidas.length > 0
                      ? _c(
                          "div",
                          _vm._l(_vm.motivosVendasPerdidas, function(
                            item,
                            index
                          ) {
                            return _c(
                              "ul",
                              {
                                key: index,
                                staticClass: "horizontal-bars pl-0"
                              },
                              [
                                _c("div", { staticClass: "progress-group" }, [
                                  _c(
                                    "div",
                                    { staticClass: "progress-group-header" },
                                    [
                                      _c("span", { staticClass: "title" }, [
                                        _vm._v(_vm._s(item.nome_motivo))
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-auto font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.qtde_motivo) + " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-muted small" },
                                            [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(
                                                    _vm._f(
                                                      "currency"
                                                    )(item.perc_motivo, {
                                                      symbol: ""
                                                    })
                                                  ) +
                                                  "%)"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "progress-group-bars" },
                                    [
                                      _c("b-progress", {
                                        staticClass: "progress-sm",
                                        attrs: {
                                          height: "{}",
                                          value: item.perc_motivo,
                                          variant: "primary"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ]),
                  _vm.readMore.vendasPerdidas
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "text-right",
                              attrs: { cols: "12", sm: "12" }
                            },
                            [
                              _c(
                                "b-link",
                                {
                                  attrs: {
                                    to: _vm.readMore.vendasPerdidas.link
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.readMore.vendasPerdidas.text)
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }