<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    displayLegend: {
      type: Boolean,
      default: true
    },
    moneyFormat: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.renderChart(
      this.chartdata,
      {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: this.displayLegend
        },
        tooltips: {
          enabled: true,
          intersect: true,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            labelColor: function (tooltipItem, chart) {
              return {
                backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor
              }
            },
            label: ((tooltipItem, data) => {
              if (this.moneyFormat) {
                const label = data.datasets[tooltipItem.datasetIndex].label;
                const valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                return `${label}: R$ ${Number(valor || 0).toLocaleString('pt-BR', {minimumFractionDigits: 2})}`;
              } else {
                return `${data.datasets[tooltipItem.datasetIndex].label}: ${(Number(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]) || 0).toLocaleString('pt-BR', {minimumFractionDigits: 0, maximumFractionDigits: 2})}`
              }
            })
          }
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            gridLines: {
              display: true
            }
          }]
        },
        elements: {
          point: {
            radius: 3,
            hitRadius: 10,
            hoverRadius: 3,
            hoverBorderWidth: 3
          }
        }
      }
    )
  }
}
</script>